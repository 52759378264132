import React from "react";
import { Link } from "react-router-dom";

const LOGO = require("../../assets/images/sign-up.png");

const UnAuth = () => {
  return (
    <>
      <div className="bg-color">
        <div
          className="container d-flex align-items-center justify-content-center"
          style={{ height: "100vh", width: "50vw" }}
        >    
          <div className="row bg-white">
            <div
              className="p-5 d-flex align-items-center  justify-content-center"
              style={{ flexDirection: "column" }}
            >
              <div className="company-wrap-logo">
                <img src="assets/images/metaprops-logo.png" />
              </div>
              <div
                className="auth-form-light"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItem: "center",
                }}
              >
                <h1 className="text-center mb-4">
                  Please Log-in to the <br></br>MetaProps Admin Panel
                </h1>

                <Link to="/login">
                  <a
                    className="btn btn-block btn-primary btn-lg font-weight-medium auth-form-btn"
                    style={{ color: "white", fontWeight: "600" }}
                  >
                    Login
                  </a>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UnAuth;
