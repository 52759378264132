import { lazy, Suspense, useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import ProtectedRoute from "./hoc/ProtectedRoute";
import { Outlet, useNavigate, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import Loader from "./commons/Loader";
import "./App.css";


import UnAuth from "./pages/UnAuth";
const Login = lazy(() => import("./pages/Authentication/Login"));
const Forget = lazy(() => import("./pages/Authentication/Forget.js"));
const Dashboard = lazy(() => import("./pages/Dashboard/Dashboard"));
const Otp_verification = lazy(() =>
  import("./pages/Authentication/Otp_verification")
);
const Reset = lazy(() => import("./pages/Authentication/Reset"));
const List = lazy(() => import("./pages/User/List"));
const CreatorList = lazy(() => import("./pages/Creator/CreatorList"));
const CollectionList = lazy(() => import("./pages/Collection/CollectionList"));
const AddCollection = lazy(() => import("./pages/Collection/AddCollection"));
const EditCollection = lazy(() => import("./pages/Collection/EditCollection"));
const EditNftCollection = lazy(() =>
  import("./pages/Collection/EditNftCollection")
);
const TermCondation = lazy(() => import("./pages/Cms/TermCondation"));
const ManageNftlist = lazy(() => import("./pages/Nfts/ManageNftlist"));
const EditTermCondation = lazy(() => import("./pages/Cms/EditTermCondation"));
const PrivacyPolicy = lazy(() => import("./pages/Cms/PrivacyPolicy"));
const EditPrivacy = lazy(() => import("./pages/Cms/EditPrivacy"));
const Faq = lazy(() => import("./pages/Cms/Faq"));
const EditFaq = lazy(() => import("./pages/Cms/EditFaq"));
const Help = lazy(() => import("./pages/Cms/Help"));
const EditHelp = lazy(() => import("./pages/Cms/EditHelp"));
const Setting = lazy(() => import("./pages/Setting/Setting"));
const Changepassword = lazy(() =>
  import("./commons/ChangePassword/Changepassword")
);

const AddFaq = lazy(() => import("./pages/Cms/AddFaq"));

const SessionExpired = lazy(() => import("./pages/SessionExpired"));

const Detail = lazy(() => import("./pages/User/Detail"));
const RemoveNft = lazy(() => import("./pages/Collection/RemoveNft"));
const CreatorStatus = lazy(() => import("./commons/Tables/CreatorStatus"));
const SignVerify = lazy(() => import("./pages/Authentication/SignVerify"));
const AddHelp = lazy(() => import("./pages/Cms/AddHelp"));

const PrivateOutlet = () => {
  const statusCode = localStorage.getItem("status") || "";
  const otpSuccess = localStorage.getItem("OTP");
  const loginSuccess = useSelector((state) => state.loginInfo.loginInfo);
  const loginStatus =
    (loginSuccess && loginSuccess.status) ||
    (statusCode && JSON.parse(statusCode) === 200 && otpSuccess) ||
    false;

  return loginStatus ? <Outlet /> : <UnAuth />;
};

const App = () => {
  return (
    <>
      <Suspense fallback={<Loader page />}>
        <Routes>
          <Route path="/unauth" element={<UnAuth />} />
          <Route path="/login" element={<Login />} />
          <Route path="/forget" element={<Forget />} />
          <Route path="/otpverification" element={<Otp_verification />} />
          <Route path="/session-expired" element={<SessionExpired />} />

          <Route path="/reset" element={<Reset />} />
          <Route path="/verification" element={<SignVerify />} />
          <Route element={<PrivateOutlet />}>
            <Route path="/" element={<Dashboard />} />
            <Route path="/list" element={<List />} />
            <Route path="/creatorlist" element={<CreatorList />} />
            <Route path="/collectionlist" element={<CollectionList />} />
            <Route path="/addcollection" element={<AddCollection />} />
            <Route path="/editcollection/:id" element={<EditCollection />} />
            <Route
              path="/editnftcollection/:id"
              element={<EditNftCollection />}
            />
            <Route path="/managenftlist" element={<ManageNftlist />} />
            <Route path="/termcondation" element={<TermCondation />} />
            <Route path="/edittermcondation" element={<EditTermCondation />} />
            <Route path="/privacy" element={<PrivacyPolicy />} />
            <Route path="/editprivacy" element={<EditPrivacy />} />
            <Route path="/faq" element={<Faq />} />
            <Route path="/editfaq/:id" element={<EditFaq />} />
            <Route path="/help" element={<Help />} />
            <Route path="/edithelp/:id" element={<EditHelp />} />
            <Route path="/setting" element={<Setting />} />
            <Route path="/changepassword" element={<Changepassword />} />
            <Route path="/detail" element={<Detail />} />
            <Route path="/remove-nft/:id" element={<RemoveNft />} />
            <Route
              path="/creatorstatus/:id/:userId"
              element={<CreatorStatus />}
            />
            <Route path="/add-faq/:id" element={<AddFaq />} />
            <Route path="/add-help/:id" element={<AddHelp />} />
          </Route>
        </Routes>
      </Suspense>
    </>
  );
};

export default App;
