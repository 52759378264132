import React from "react";
import { TailSpin } from "react-loader-spinner";

const Loader = ({ page }) => {
  return (
    <div
      style={{
        width: "100%",
        height: page ? "100vh" : "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <TailSpin color="#4472C7" width={40} height={40} />
    </div>
  );
};

export default Loader;
