import { createSlice } from "@reduxjs/toolkit";

export const getNftSlice = createSlice({
  name: "nftData",
  initialState: {
    nftData: [],
    collectionNftData: [],
  },
  reducers: {
    setNftData: (state, action) => {
      state.nftData = action.payload;
    },
    setCollectionNftData: (state, action) => {
      state.collectionNftData = action.payload;
    },
    setCollectionNftDataPage: (state, action) => {
      state.collectionNftData = [...state.collectionNftData, ...action.payload];
    },
    setNftPage: (state, action) => {
      state.nftData = [...state.nftData, ...action.payload];
    },
  },
});

export const {
  setNftData,
  setNftPage,
  setCollectionNftData,
  setCollectionNftDataPage,
} = getNftSlice.actions;

export default getNftSlice.reducer;
