import { createSlice } from '@reduxjs/toolkit';

export const getVerificationSlice = createSlice({
	name: 'verification',
	initialState: {
		veriInfo: {},
	},
	reducers: {
		setVeriInfo: (state, action) => {
			state.veriInfo = action.payload;
		},
	}
});

export const { setVeriInfo } = getVerificationSlice.actions;

export default getVerificationSlice.reducer;