import { createSlice } from '@reduxjs/toolkit';

export const getUserSlice = createSlice({
	name: 'userInfo',
	initialState: {
		userInfo: {},
	},
	reducers: {
		setUserInfo: (state, action) => {
			state.userInfo = action.payload;
		},
	}
});

export const { setUserInfo } = getUserSlice.actions;

export default getUserSlice.reducer;