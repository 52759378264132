import { createSlice } from '@reduxjs/toolkit';

export const getCollectionSlice = createSlice({
	name: 'userInfo',
	initialState: {
		collectionInfo: {}
	},
	reducers: {
		setCollectionData: (state, action) => {
			state.collectionInfo = action.payload;
		},
	}
});

export const { setUserInfo, setCollectionData } = getCollectionSlice.actions;

export default getCollectionSlice.reducer;