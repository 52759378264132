import { configureStore } from "@reduxjs/toolkit";
import getUserLoginSlice from "./slice/Login";
import getCollectionSlice from "./slice/Creator/index";
import getUserSlice from "./slice/user";
import getNftSlice from "./slice/Nft";
import getVerificationSlice from "./slice/Verification";
// import  getCmsSlice  from "./slice/Cms";

const store = configureStore({
  reducer: {
    userInfo: getUserSlice,
    loginInfo: getUserLoginSlice,
    collectionInfo: getCollectionSlice,
    nftData: getNftSlice,
    veriInfo: getVerificationSlice,
    // CmsInfo: getCmsSlice,
  },
});

export default store;
