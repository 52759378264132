import { createSlice } from "@reduxjs/toolkit";

export const getUserLoginSlice = createSlice({
  name: "login",
  initialState: {
    loginInfo: {},
  },
  reducers: {
    setLoginInfo: (state, action) => {
      state.loginInfo = action.payload;
    },
  },
});

export const { setLoginInfo } = getUserLoginSlice.actions;

export default getUserLoginSlice.reducer;
